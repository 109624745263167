"use strict";
import nq from './nativeQuery';

const OverlayManager = function () {
	this.list = [];
	this.refCount = 0;


	this.create = () => {
		const ref = "o" + this.refCount++;
		const overlay = new Overlay(this, ref);
		this.list.push({ id: ref, overlay });
		return overlay;
	}


	this.close = ref => {
		this.list = this.list.filter(a => {
			if (a.id === ref)
				a.overlay._remove();
			else
				return 1;
		});
	}


	this.none = () => {
		return this.list.length == 0;
	};


	this.closeAll = () => {
		for (const item of this.list)
			item.overlay._remove();

		this.list = [];
	}

	this.closeLast = () => {
		this.close(this.list[this.list.length - 1].id);
	};

	this.escapeEvent = () => {
		if (this.list.length) {
			this.list[this.list.length - 1].overlay.escapeEvent();
			return true;
		}
		
		return false;
	};
};



const Overlay = function (manager, reference) {
	const root = nq.create("div").addClass("_overlay").attr('role', "dialog")
		.attr("aria-labelledby", " _dlgTitle").attr("aria-describedby", "_dlgDesc").appendTo(document.body);
	const container = nq.create("div").addClass("_ovContainer").attr('id', reference).appendTo(root);
	const title = nq.create("div").addClass("_ovTitle").appendTo(container);
	const content = nq.create("div").addClass("_ovContent").attr('id', "_dlgDesc").appendTo(container);
	const buttonsContainer = nq.create("div").addClass("_ovButtonsContainer").appendTo(container);
	
	const id = "_overlay";
	const escapeCallback = () => { this.close() };
	let isVisible = false;


	this.init = () => {
		root.attr('aria-hidden', 'true')		

		for (const item of ["header", "main", "footer"])
			nq(item)?.attr('aria-hidden', 'false');
	}


	this.setTitle = text => {
		title.innerText = text;
	}


	this.setClass = sClass => {
		root.addClass('sClass');
	};

	this.setEscapeKeyCallback = callback => {
		escapeCallback = callback;
	}

	this.escapeEvent = () => {
		escapeCallback();
	};

	this.getContainer = () => {
		return content;
	};

	this.getButtonsContainer = () => {
		return buttonsContainer;
	}

	this.hide = () => {
		isVisible = false;
		root.hide();
		root.attr('aria-hidden', 'true');

		for (const item of ["header", "main", "footer"])
			nq(item)?.attr('aria-hidden', 'false');

		// remove the listener grabbing focus
		//$('header, #site-scroll').off('focusin','grabPageFocus');
		for (const item of ["header", "main", "footer"])
			document.querySelector(item)?.removeEventListener('focusin', this._grabPageFocus);

		if (this.beforeFocused)
			this.beforeFocused.focus();
	};

	this.close = () => {
		manager.close(reference);
	};

	this.toggle = () => {
		if(!isVisible)
			this.show();
		else
			this.hide();
	}

	this._remove = () => {
		this.hide();
		root.remove();
	}


	this.show = () => {
		isVisible = true;
		for (const item of ["header", "main", "footer"])
			nq(item)?.attr('aria-hidden', 'true');

		root.show();
		root.attr('aria-hidden', 'false');

		this.focusableItems = this.getFocusableItems();
		this.beforeFocused = document.activeElement;

		// grab tab event and redirect to overlay
		for (const item of ["header", "main", "footer"])
			nq(item)?.on('focusin', this._grabPageFocus);

		this.focusFirstItem();

		root.on("keydown", this.processTabKeyDown);
	}

	this._grabPageFocus = () => {
		this.focusFirstItem();
	}

	this.focusFirstItem = () => {
		this.focusableItems[0].focus();
	}	

	this.focusLastItem = () => {
		this.focusableItems[this.focusableItems.length - 1].focus();
	}

	this.getFocusableItems = () => {
		const focusableList = "a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]";
		//return this.root.find('*').filter(focusableList).filter(':visible');
		return root.findAll(focusableList);
	}

	this.processTabKeyDown = evt => {
		// Tab or shift-tab pressed
		if (evt.which == 9) {
			// get currently focused item
			const curFocus = document.activeElement;
			const count = this.focusableItems.length;
			const curIndex = [].indexOf.call(this.focusableItems, curFocus);

			if (evt.shiftKey && curIndex == 0) {
				evt.preventDefault();
				this.focusLastItem();
			}
			else if (!evt.shiftKey && curIndex == count - 1) {
				evt.preventDefault();
				this.focusFirstItem();
			}
		}
	}


	this.init();
};



export { OverlayManager, Overlay };
