"use strict";
import nq from '../../nativeQuery.js';

const Breadcrumb = function (app)
{
	const main_container = nq("#breadcrumb");
	const classical_nav = nq("#classicalNav");

	this.updateCrumb = (crumbs) => {
		main_container.empty()

		if(main_container.classList.contains("hidden"))
			main_container.classList.remove("hidden")
		if(!classical_nav.classList.contains("hidden"))
			classical_nav.classList.add("hidden");

		for(const crumb of crumbs)
		{
			const button = app.UI.button()
			button.container.append(crumb.name).addClass("crumbOfBread")
			button.setActionCallback(() => {
				crumb.callback();
			})
			button.container.appendTo(main_container)
		}
	}

	this.intoNav = () => 
	{
		main_container.empty();
		if(!main_container.classList.contains("hidden"))
			main_container.classList.add("hidden");
		if(classical_nav.classList.contains("hidden"))
			classical_nav.classList.remove("hidden");	
	}

}

export default Breadcrumb;