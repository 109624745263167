"use strict";

 const Dispatcher = function(){
  this.events = new Map();
  
  this.subscribe = (evt, callback) =>{
    const list = this.events.get(evt);
    if(!list){
      this.events.set(evt, [callback]);
    }
    else{
      list.push(callback);
    }
  };
  
  this.emit = (evt, ...args) => {
      const cbList = this.events.get(evt);
      if(cbList === undefined){
        console.warn('No subscription for event: event', evt,);
        return; 
      }
      for(const cb of cbList){
        cb(...args);
      }
  };
  
  this.reset = () => {
		this.events = new Map();
	}
};

export {Dispatcher};
