import { App } from './App';

const app = new App();

window.addEventListener("DOMContentLoaded", () => {
	app.init()
	.then( () => {
		app.router.addPage("/", () => window.location.href = "/");
		
		app.router.addPage("/edition", (data, url) => {
			import('./views/edition/main.js')
			.then( ({default: Edition})  => {
				new Edition(app).load(data, url);
				app.containers.breadcrumb.updateCrumb([{name: "Edition", callback: () => {}}]);
			});
		});
		
		app.router.addPage("/password-reset", (data, url) => {
			import('./views/passwordReset/main.js')
			.then( ({default: PasswordReset})  => {
				new PasswordReset(app).load(data, url);
				app.containers.breadcrumb.updateCrumb([{name: "Reset Password", callback: () => {}}]);
			});
		});
		
		app.router.addPage("/projects", (data, url) => {
			import('./views/projects/main.js')
			.then( ({default: Projects})  => {
				new Projects(app).load(data, url);
				app.containers.breadcrumb.updateCrumb([{name: "Project List", callback: () => {}}]);
			});
		});
		
		app.router.addPage("/projects/new", (data, url) => {
			import('./views/projects/main.js')
			.then( ({default: Projects})  => {
				new Projects(app).loadNew(data, url);
				app.containers.breadcrumb.updateCrumb([{name: "New Project", callback: () => {}}]);
			});
		});
		
		app.router.addPage("/projects/new/blank", (data, url) => {
			import('./views/projects/main.js')
			.then( ({default: Projects})  => {
				new Projects(app).loadBlank(data, url);
				app.containers.breadcrumb.updateCrumb([{name: "Create Blank Project", callback: () => {}}]);
			});
		});
		
		app.router.addPage("/projects/settings", (data, url) => {
			import('./views/projects/projectSettings.js')
			.then( ({default: ProjectSettings})  => {
				new ProjectSettings(app).load(data, url);
				app.containers.breadcrumb.updateCrumb([{name: "Project Settings", callback: () => {}}]);
			});
		});
		
		app.router.addPage("/projects/settings/*", (data, url) => {
			import('./views/projects/projectSettings.js')
			.then( ({default: ProjectSettings})  => {
				new ProjectSettings(app).load(data, url);
				app.containers.breadcrumb.updateCrumb([{name: "Project Settings", callback: () => {}}]);
			});
		});
		
		app.router.addPage("/admin", (data, url) => {
			import('./views/admin/main.js')
			.then( ({default: Admin})  => {
				new Admin(app).load(data, url);
				app.containers.breadcrumb.updateCrumb([{name: "Administration", callback: () => {}}]);
			});
		});
		
		app.router.addPage("/admin/*", (data, url) => {
			import('./views/admin/main.js')
			.then( ({default: Admin})  => {
				new Admin(app).load(data, url);
				app.containers.breadcrumb.updateCrumb([{name: "Administration", callback: () => {}}]);
			});
		});
		
		app.router.addPage("/account", (data, url) => {
			import('./views/account/main.js')
			.then( ({default: Account})  => {
				new Account(app).load(data, url);
				app.containers.breadcrumb.updateCrumb([{name: "Account", callback: () => {}}]);
			});
		});
		
		app.router.addPage("/account/*", (data, url) => {
			import('./views/account/main.js')
			.then( ({default: Account})  => {
				new Account(app).load(data, url);
				app.containers.breadcrumb.updateCrumb([{name: "Account", callback: () => {}}]);
			});
		});

		app.router.addPage("/account/themes", (data, url) => {
			import('./views/account/tabs/themes.js')
			.then(({default: themes})  => {
				new themes(app).load(data, url);
				app.containers.breadcrumb.updateCrumb([{name: "Themes Management", callback: () => {}}]);
			});
		});

		app.router.addPage("/account/info", (data, url) => {
			import('./views/account/tabs/info.js')
			.then(({default: info})  => {
				new info(app).load(data, url);
				app.containers.breadcrumb.updateCrumb([{name: "Informations", callback: () => {}}]);
			});
		});

		app.router.addPage("/account/advanced", (data, url) => {
			import('./views/account/tabs/advanced.js')
			.then(({default: advanced})  => {
				new advanced(app).load(data, url);
				app.containers.breadcrumb.updateCrumb([{name: "Advanced Settings", callback: () => {}}]);
			});
		});

		app.router.addPage("/account/authentification", (data, url) => {
			import('./views/account/tabs/authentication.js')
			.then(({default: authentification})  => {
				new authentification(app).load(data, url);
				app.containers.breadcrumb.updateCrumb([{name: "Authentification", callback: () => {}}]);
			});
		});

		app.router.addPage("/documentation", (data, url) => {
			app.containers.breadcrumb.intoNav();
		})

		app.router.addPage("/about", (data, url) => {
			app.containers.breadcrumb.intoNav();
		})

		app.router.addPage("/contact", (data, url) => {
			app.containers.breadcrumb.intoNav();
		})

		if (document.querySelector(".home_container") !== null)
		{
			if(!app.ifLoggedRedirectTo("/projects")) {
				import("./Sign-in-up.js")
				.then( ({default: SignInUp}) => {
					new SignInUp(app);
				});
			}
		}
		else
			app.router.retrievePage();
	});
});
