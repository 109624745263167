"use strict";
import nq from './nativeQuery';



const Form = function (app, formId) {
  const _elements = [];
  this.values = {};

  this.add = (type, name, label, defaultValue, userValue, placeholder) => {
    const valueObj = {default: defaultValue, current: userValue ?? defaultValue}
    this.values[name] = valueObj
    const field = new Field(app, type, name, label, valueObj, placeholder);
    _elements.push(field);
    return field;
  };

  this.addButtonLine = cssClass => {
    const btLine = new ButtonLine(app, cssClass);
    _elements.push(btLine);
    return btLine;
  }

  this.addCustomArea = cssClass => {
    const area = new CustomArea(cssClass);
    _elements.push(area);
    return area;
  }

  this.showResetButton = (textContent) => {
    this._resetButtonContent = textContent || "";
  }

  this.generate = () => {
    const container = nq.create('form').attr('id', formId);
    nq.create('input').attr('type', 'submit').hide().appendTo(container)
    
    container.on("submit", event => {
      event.preventDefault()
      if(this.onsubmitcallback != undefined)
      {
        this.onsubmitcallback();
      }
      return false;
    })
    
    if (this._resetButtonContent !== undefined)
      container.append(this._genResetButton());

    for (const el of _elements)
      container.append(el.generate(formId));

    return container;
  }

  this._genResetButton = () => {
    const bt = app.UI.button();
    if (this._resetButtonContent.length)
      bt.container.append(this._resetButtonContent).addClasses("button cancel")
    else {
      bt.container.attr("title", "reset form")
      app.loadSVG("/img/reset.svg")
        .then((svg) => {
          bt.container.append(svg).addClass("button")
        });
    }

    bt.setActionCallback(() => {
      for (const el of _elements) {
        if (el instanceof Field) {
          el.setUserValue(undefined);
          el.update();
        }
      }
    });

    return nq.create("div").addClass("resetButtonContainer")
      .append(bt.container);
  };


  this.export = () => {
    const data = {};

    for (const el of _elements) {
      if (el instanceof Field) {
        data[el.getName()] = el.getValue();
      }
    }
    return data;
  }

  this.restoreValues = data => {
    // for (const el of _elements) {
    //   if (el instanceof Field) {
    //     if (data[el.getName()] !== undefined) {
    //       el.setUserValue(data[el.getName()]);
    //       // el.update();
    //     }
    //   }
    // }
    for(const datum in this.values)
    {
      if(data[datum] !== undefined)
        this.values[datum].current = data[datum].current;
    }
  }
  
  this.onsubmit = callback => {
  this.onsubmitcallback = callback;
  }
}





const ButtonLine = function (app, cssClass) {
  this._buttons = [];

  this.addButton = (content, title, callback) => {
    this._buttons.push({ content, title, callback });
    return this;
  }

  this.generate = () => {
    const container = nq.create('div').addClass('buttonLine');
    if (cssClass)
      container.addClass(cssClass);
    for (const button of this._buttons) {
      const bt = app.UI.button(button.content, button.title);
      bt.container.addClass("button").appendTo(container);
      bt.setActionCallback(data => {
        if (button.callback !== undefined)
          button.callback(data);
      });
    }
    return container;
  }
}



const CustomArea = function (cssClass) {

  this.generate = () => {
    const container = nq.create('div').addClass('customArea');

    if (cssClass)
      container.addClass(cssClass);

    return container;
  }
}


const Field = function (app, type, name, label, valueObj, placeholder) {
  this.node = undefined;
  this._attributes = [];
  this._options = [];


  this.addHelp = (title, content) => {
    this._helpTitle = title;
    this._helpContent = content;
    return this;
  }

  this.addOption = (label, value) => {
    this._options.push([label, value]);
    return this;
  }

  this.resetOptions = () => {
    this._options = [];
    return this;
  }

  this.addAttribute = (attribute, value) => {
    this._attributes.push([attribute, value]);
    return this;
  }

  this.addComment = comment => {
    this._comment = comment;
    return this;
  }

  this.addConstraints = constraints => {
    this._constraints = constraints;
  }

  this.showRange = () => {
    this._showRange = true;
  }

  this.getName = () => {
    return name;
  }

  this.getValue = () => {
    if (this.node === undefined)
      return valueObj.current ?? valueObj.default;

    return this.node.value;
  }

  this.update = () => {
    if (type === "select") {
      const val = valueObj.current ?? valueObj.default;
      for (const option of this._options)
        option.node.selected = option.node.value === val;
    }
    else if (type === "checkbox") {
      this.node.checked = valueObj.current ?? valueObj.default;
    }
    else if(type === "file")
      return;
    else
      this.node.value = (valueObj.current ?? valueObj.default) || "";
  }

  this.setUserValue = value => {
    valueObj.current = value;
    if (this._onChangeCallback !== undefined)
      this._onChangeCallback(valueObj.current);
  }


  this.setOnChangeCallback = callback => {
    this._onChangeCallback = callback;
    return this;
  }

  this.generate = formId => {
    const container = nq.create('div').addClass("fieldContainer");

    if (type === "checkbox")
      container.addClass("switchField");
    const id = formId + "_" + name;
    container.append(this._genLabel(id));

    if (this._comment !== undefined)
      container.append(nq.create('div').addClass("comment").append(this._comment));

    if (type === "select")
      container.append(this._genSelect(id));
    else if (type === "checkbox")
      container.append(this._genCheckbox(id));
    else if (type === "textarea")
      container.append(this._genTextArea(id));
    else
      container.append(this._genInput(id));

    if (this._constraints !== undefined) {
      const ul = nq.create('ul').addClass("fieldConstraints").appendTo(container);
      for (const c of this._constraints)
        c[3] = nq.create('li').attr('id', 'passwordConstraint_' + c[0]).append(c[1]).appendTo(ul)
    }

    this.node.on("keyup", () => {
      if (this._constraints)
        this._checkConstraints();
    })

    return container;
  }

  this._genLabel = fieldId => {
    const labelDiv = nq.create('label').append(label).attr('for', fieldId);

    if (this._helpContent && this._helpTitle) {
      const bt = app.UI.button("?", "Help");
      bt.container.addClass("helpBt").appendTo(labelDiv);
      bt.setActionCallback(() => {
        app.dialog().info(this._helpTitle, this._helpContent);
      });
    }

    return labelDiv;
  }

  this._genInput = id => {
    this.node = nq.create('input')
      .attr('type', type)
      .attr('value', (valueObj.current ?? valueObj.default) || "")
      .attr('placeholder', placeholder || "")
      .attr('id', id);

    this._addAttributes();
    this.update();		// used to restore user or default value

    if (type === "password") {
      const inputContainer = nq.create('div').addClass('passwordInput').append(this.node);
      const eyeBt = app.UI.button();
      eyeBt.container.attr("title", "Show/hide password")
        .addClass("input_eye")
        .appendTo(inputContainer);

      app.loadSVG("/img/eye.svg")
        .then(svg => {
          eyeBt.container.append(svg);
        })

      eyeBt.setActionCallback(() => {
        console.log("change password field type");
        this.node.type = this.node.type === "password" ? "text" : "password";
      });

      return inputContainer;
    }
    else if (type !== "number" || !this._showRange)
    {
      // to do changes event listening
      this.node.on("keyup", () => {
        this.setUserValue(this.node.value);
      })
      return this.node;
    }

    const range = nq.create('input').attr('type', 'range');
    const input = this.node;
    this.node = nq.create('div').append(this.node).append(range);

    range.on("change", () => input.value = range.value)

    input.onAll("keyup change", () => range.value = input.value)


    this._addAttributes(range);
    return this.node;
  }

  this._genTextArea = id => {
    this.node = nq.create('textarea').attr('id', id);

    this.node.on("keyup", () => {
      this.setUserValue(this.node.value);
    })

    this.update(); // used to restore user or default value
    
    return this.node;
  }

  this._genCheckbox = id => {
    const container = nq.create('div').addClass('switchContainer');
    this.node = nq.create('input').attr('id', id).attr('type', 'checkbox').appendTo(container);
    nq.create('label').attr('for', id).appendTo(container);

    this._addAttributes();
    this.update();		// used to restore user or default value

    this.node.on("change", () => {
      this.setUserValue(this.node.checked);
    })
    return container;
  }

  this._genSelect = id => {
    this.node = nq.create('select').attr('id', id);

    for (const option of this._options) {
      option.node = nq.create('option').attr('value', option[1]).append(option[0])
        .appendTo(this.node);
    }

    this._addAttributes();
    this.update();		// used to restore user or default value

    this.node.on("change", () => {
      this.setUserValue(this.node.value);
    })
    return this.node;
  }

  this._addAttributes = node => {
    node = node || this.node;
    for (const attr of this._attributes)
      node.attr(attr[0], attr[1]);
  }

  this._checkConstraints = () => {
    for (const constraint of this._constraints) {
      if (constraint[2](this.node.value))
        constraint[3].addClass("checked");
      else
        constraint[3].removeClass("checked");
    }
  }
}


export default Form;