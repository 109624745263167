/** Main application router
 * description: Manage app URLs, set URL and retrieve
 * 				concerned app page. It also manage hidden
 * 				and visible URL data.
*/

const Router = function (overlayManager) {
	this.currentState = {};
	this.backedState = "";
	this.previousPage = undefined;
	this.pageList = new Map();

	this.addPage = (pageName, loadCallback, unloadCallback) => {
		this.pageList.set(pageName, { load: loadCallback, unload: unloadCallback });
	}

	this.retrievePage = () => {
		this.currentState = this.stateFromUrl();

		if (this.currentState.page === "/")
			return;

		this.goTo(this.currentState.page, this.currentState.data);
	}

	this.goTo = (page, data) => {
		const prevCallbacks = this.pageList.get(this.previousPage);
		if (prevCallbacks !== undefined && prevCallbacks.unload !== undefined)
			prevCallbacks.unload();

		const pageCallbacks = this._findMatch(page);
		
		if (pageCallbacks !== undefined) {
			this.updateUICallback();
			pageCallbacks.load(data || {}, page);
			return;
		}
		else
			console.log("Go to Ignored");
	}
	
	this._findMatch = page => {
		const callbacks = this.pageList.get(page);
		
		if(callbacks !== undefined)
			return callbacks;
			
				const parts = page.split("/");
let i = 0;
		
		for(const [key, value] of this.pageList) {
			const keyParts = key.split("/");
			
			if(parts.length !== keyParts.length)
				continue;
			
			for(i=keyParts.length-1; i >= 0; i--) {
				if(keyParts[i] === "*")
					continue;
				
				if(parts[i] !== keyParts[i])
					break;
								}

								if(i === -1)
					return value;
		}
	}

	this.setUIRefreshCallback = callback => {
		this.updateUICallback = callback;
	}

	this.backupCurrentState = () => {
		this.backedState = JSON.stringify(this.currentState)
	}

	this.currentStateIsDifferent = () => {
		return JSON.stringify(this.currentState) !== this.backedState;
	}

	this.push = (pageName, data) => {
		this._pushReplacePage(pageName, data, false);
	}

	this.replace = (pageName, data) => {
		this._pushReplacePage(pageName, data, true);
	}

	this._pushReplacePage = (pageName, data, replace) => {
		this.previousPage = this.currentState.page;
		this._setCurrentStateData(pageName, data);

		if (replace)
			history.replaceState([], '', this.urlState());
		else {
			if (this.currentStateIsDifferent()) {
				history.pushState([], '', this.urlState());
				this.backupCurrentState();
			}
			else
				console.log("Ignored");
		}
	}

	this._setCurrentStateData = (page, data) => {
		this.currentState.page = page;
		this.currentState.data = data;
	}

	this.urlState = () => {
		let str = this.currentState.page !== "/" ? this.currentState.page : "";
		if (this.currentState.data !== undefined)
			str += "?" + (new URLSearchParams(this.currentState.data).toString());
		return str;
	}

	this.stateFromUrl = () => {
		const searchParams = new URLSearchParams(window.location.search);
		const page = window.location.pathname;

		let data = {};

		searchParams.forEach((value, key) => {
			data[key] = value;
		})

		return { page, data };
	}


	this.init = () => {
		window.onpopstate = () => {
			overlayManager.closeAll();
			this.retrievePage();
			this.backupCurrentState();
		};
	}

	this.init();
}


export { Router };
