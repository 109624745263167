"use strict";
import nq from '../../nativeQuery.js';

const Navigation = function (app, container, title, baseURL, data) {
  if(baseURL === undefined)
		return console.error("Navigation object require baseURL parameter");
		
  this.baseURL = baseURL.charAt(baseURL.length-1) === "/" ? baseURL.substring(0, baseURL.length-1) : baseURL;
  nq.create('h2').append(title).appendTo(container);
  this.tabContainer = nq.create('div').appendTo(container);
  this.tabs = new Map();
  this.selectedTab = undefined;


  this.add = (tabId, icon, tabName, callback) => {
    
    const bt = app.UI.button();
    
		const iconContainer = nq.create('div').addClass("iconContainer").appendTo(bt.container, 0);
    bt.container.append(nq.create("div").append(tabName)).appendTo(this.tabContainer);
    
    if(icon !== undefined && icon.length) {
			if(icon.endsWith(".svg")) {
				app.loadSVG(icon)
				.then( svg => {
					iconContainer.append(svg);
				});
			}
			else
				iconContainer.append(nq.create('img').attr('src', icon));
		}
    
    bt.setActionCallback(() => {
				this.selectTab(tabId) 
			});

    this.tabs.set(tabId, { name: tabName, callback, node: bt.container });
  }

  this.addButton = (icon, label, callback) => {
		const bt = app.UI.button();
    const iconContainer = nq.create('div').addClass("iconContainer");
    bt.container.append(iconContainer).append(nq.create("div").append(label)).appendTo(this.tabContainer);
    
    if(icon !== undefined && icon.length) {
			if(icon.endsWith(".svg")) {
				app.loadSVG(icon)
				.then( svg => {
					iconContainer.append(svg);
					
				});
			}
			else
				iconContainer.append(nq.create('img').attr('src', icon));
		}
    
    bt.setActionCallback(() => {
			callback();
		});
	}
  
  this.addSeparator = () => {
			this.tabContainer.append(nq.create('hr'));
	}
	  
  this.selectTab = (tabId) => {
    if (this.selectedTab !== undefined)
      this.tabs.get(this.selectedTab).node.removeClass('selected');

    const tab = this.tabs.get(tabId);
    if (tab === undefined)
      return;

    tab.node.addClass('selected');
    tab.callback();
    // TL DR / TO DO additionnal functionnality should be IN the specialised element rather than in navigation.js
    // if(!noHistory && this.selectedTab !== tabId) {
		// 	if(replaceState)
		// 		app.router.replace(baseURL+"/"+tabId, data);
		// 	else
		// 		app.router.push(baseURL+"/"+tabId, data);
		// }
		this.selectedTab = tabId;
  }
  
  this.retrieveTab = pageURL => {
		const tab = pageURL.substring(baseURL.length+1);
		
		this.selectTab(tab);
	}
}

export default Navigation;
