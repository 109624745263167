"use strict";
import nq from './nativeQuery.js';

const Dialog = function(app, overlay) {
	return {
		confirm: function(title, content, confCallback, cancelCallback, textYes, textNo) {
			textYes = textYes || "Confirm";
			textNo = textNo || "Cancel";
			
			overlay.setTitle(title)
			const ovContainer = overlay.getContainer();
			ovContainer.append(content);
			const buttonsContainer = overlay.getButtonsContainer();
			
			const yesBt = app.UI.button(textYes);
			const noBt = app.UI.button(textNo);
			
			noBt.container.addClasses('button cancel').appendTo(buttonsContainer);
			yesBt.container.addClasses('button confirm').appendTo(buttonsContainer);
			
			yesBt.setActionCallback(() => {
				overlay.close();
				if(confCallback !== undefined)
					confCallback();
			});
			
			noBt.setActionCallback(() => {
				overlay.close();
				if(cancelCallback !== undefined)
					cancelCallback();
			});
			
			overlay.show();
			return overlay;
		},
		

		info: function(title, content, callback, textOk) {
			textOk = textOk || "Ok";
			
			overlay.setTitle(title)
			const ovContainer = overlay.getContainer();
			ovContainer.append(content);
			
			const buttonsContainer = overlay.getButtonsContainer();
			
			const bt = app.UI.button(textOk);
			bt.container.addClasses('button confirm').appendTo(buttonsContainer);
			
			bt.setActionCallback(() => {
				overlay.close();
				if(callback !== undefined)
					callback();
			});
			
			overlay.show();
			return overlay;
		},


		
		triple: function(title, content, bt1Callback, bt2Callback, bt3Callback, textBt1, textBt2, textBt3) {
			overlay.setTitle(title);
			const ovContainer =overlay.getContainer();
			ovContainer.append(content);
			const buttonsContainer = overlay.getButtonsContainer();
			
			const bt1 = app.UI.button(textBt1);
			const bt2 = app.UI.button(textBt2);
			const bt3 = app.UI.button(textBt3);
			
			bt1.container.addClasses('button confirm').appendTo(buttonsContainer);
			bt2.container.addClasses('button cancel').appendTo(buttonsContainer);
			bt3.container.addClass('button').appendTo(buttonsContainer);
			
			bt1.setActionCallback(() => {
				overlay.close();
				if(bt1Callback !== undefined)
					bt1Callback();
			});
			
			bt2.setActionCallback(() => {
				overlay.close();
				if(bt2Callback !== undefined)
					bt2Callback();
			});
			
			bt3.setActionCallback(() => {
				overlay.close();
				if(bt3Callback !== undefined)
					bt3Callback();
			});
			
			overlay.show();
			return overlay;
		}
	}
};

export {Dialog};
