"use strict";
import nq from './nativeQuery.js';


const PageList = function (app) {
	this.container = nq.create('div').addClass('pageList');
	this._pageElements = [];
	this._totalCount = 0;
	this._perPage = 1;
	this._currentPage = 0;
	this._callback = () => { };

	this.setTotalCount = count => {
		this._totalCount = count;
		this._draw();
	}

	this.setPerPage = number => {
		this._perPage = number;
		this._draw();
	}

	this.setCurrentPage = page => {
		page = parseInt(page);
		if (page > this._totalCount)
			return;

		if (this._pageElements.length !== 0) 
		{
			this._pageElements[this._currentPage + 1].removeClass("current");
			this._pageElements[page + 1].addClass("current");
		}

		this._currentPage = page;
	}

	this.setCallback = callback => {
		this._callback = callback;
	}

	this._draw = () => {
		// Clear
		this._pageElements = [];
		this.container.empty();

		const totalPage = Math.ceil(this._totalCount / this._perPage);

		const firstBt = app.UI.button('First');
		firstBt.container.appendTo(this.container);
		firstBt.setActionCallback(() => {
			this.setCurrentPage(0);
			this._callback(0)
		});
		this._pageElements.push(nq(firstBt.container));

		for (let i = 0; i < totalPage; i++) {
			const bt = app.UI.button(i + 1);
			bt.container.appendTo(this.container);
			bt.setActionCallback(() => {
				this.setCurrentPage(i);
				this._callback(i)
			});
			this._pageElements.push(nq(bt.container));
		}

		const lastBt = app.UI.button('Last');
		lastBt.container.appendTo(this.container);
		lastBt.setActionCallback(() => {
			this.setCurrentPage(totalPage - 1);
			this._callback(totalPage - 1)
		});
		this._pageElements.push(nq(lastBt.container));

		// In case of setCurrentPage is called by the user before drawing elements
		this.setCurrentPage(this._currentPage);
	}
}

export default PageList;
