import { io } from "socket.io-client";
const configs = require("../../../env.json")
export default function Notifications(app){
	const socket = io(configs.notificationsServiceRoot, {path:  configs.notificationsServicePath + "/socket.io"})

	socket.emit("link", app.userData.accessToken)

	socket.on("workflowStateUpdate", projectId => {
		app.dispatcher.emit("workflowStateUpdate", projectId)
	})
}