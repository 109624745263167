"use strict";
import nq from './nativeQuery';
import Form from './Form.js';
import PageList from './paging.js';

const UI = function(app) {
  this.button = (content, tooltip) => {
    return new Button(content, tooltip);
  },

  this.switch = (id) => {
    return new SwitchButton(id);
  },
  
  this.form = (formId) => {
		return new Form(app, formId);
	}
	
	this.pageList = () => {
		return new PageList(app);
	}
};


const Button = function (content, tooltip, disabled) {
  this._actionCallback = undefined;
  this.container = nq.create("div").attr('role', 'button').attr("tabIndex", "0")
  .append(content || "").attr("title", tooltip || "");

  this.setActionCallback = callback => {
    this._actionCallback = callback;
  };

  this.container.on("click", () => {
    console.log("button container click", disabled ? "disabled" : "");
    if (!disabled && this._actionCallback) {
      this._actionCallback(this);
      // Prevent click propagation
      return false;
    }
  });

  this.container.on("keydown", evt => {
    console.log("button key down");
    if (evt.code ==="Space" || evt.code === "Enter")
      if (!disabled && this._actionCallback) {
        evt.preventDefault();
        this._actionCallback(this);
      }
  });

  this.setData = data => {
    this.data = data;
  };

  this.disable = () => {
    this.container.addClass("disabled");
    this.disabled = true;
    this.container.disabled = true;
  };

  this.enable = () => {
    this.container.removeClass("disabled");
    this.disabled = false;
    this.container.disabled = false;
  };
};

const SwitchButton = function (id) {
  this.container = nq.create("div").addClass("switchContainer");
  this.input = nq.create("input").attr('id', id).attr('type', "checkbox").appendTo(this.container);
  const lbl = nq.create("label").attr("for", id).appendTo(this.container);
  

  this.check = () => {
    this.input.checked = "checked";
  }

  this.uncheck = () => {
    this.input.checked = "";
  }

  this.onChange = callback => {
    this.input.on( 'change', evt => {
      callback(this.input.value);
    });
  }
};

export default UI;
