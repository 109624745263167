/** native query library
 * @description: Simple and light weight library to ease vanilla js function usage
 * @author: Yoann Bonavero
 */


/* Create document element with nativeQuery associated functions
 * or nativeQueryfy an existing element
 */
function nq(selectorOrNode) {
	let obj = selectorOrNode;

	if (typeof selectorOrNode === 'string')
		obj = document.querySelector(selectorOrNode);

	if (obj) {
		assocFuncs(obj);
		return obj;
	}
}

nq.create = function (DOMElement) {
	const obj = document.createElement(DOMElement);
	assocFuncs(obj);
	return obj;
}

nq.createNS = function (ns, DOMElement) {
	const obj = document.createElementNS(ns, DOMElement);
	assocFuncs(obj);
	return obj;
}

function assocFuncs(obj) {

	obj.find = function (selector) {
		const child = obj.querySelector(selector);
		if (child) {
			assocFuncs(child);
			return child;
		}
	}

	obj.findAll = function (selector) {
		const children = obj.querySelectorAll(selector);
		for (const child of children)
			assocFuncs(child);
		return children;
	}

	obj.addClass = function (className) {
		obj.classList.add(className);
		return obj;
	}
	
	obj.addClasses = function (classNames) {
		classNames.split(" ").map( c => obj.classList.add(c));
		return obj;
	}

	obj.removeClass = function (className) {
		obj.classList.remove(className);
		return obj;
	}

	obj.removeClasses = function (className) {
		classNames.split(" ").map( c => obj.classList.remove(c));
		return obj;
	}

	obj.attr = function (attribute, value) {
		if (value === undefined)
			return obj.getAttribute(attribute);
		obj.setAttribute(attribute, value);
		return obj;
	}

	obj.attrNS = function (ns, attribute, value) {
		if (value === undefined)
			return obj.getAttributeNS(ns, attribute);
		obj.setAttributeNS(ns, attribute, value);
		return obj;
	}
	
	obj.append = content => {
		if (typeof content === "object")
			obj.appendChild(content);
		else
			obj.innerText = obj.innerText + content;
		return obj;
	}

	obj.appendTo = container => {
		container.append(obj);
		return obj;
	}

	obj.prepend = content => {
		obj.insert(content, 0);
	}
	
	obj.insert = (content, index) => {
		index = index || 0;
		if (index >= obj.children.length)
			obj.append(content)
		else
			obj.insertBefore(content, obj.children[index])
		return obj;
	}

	obj.insertTo = (container, index) => {
		container.insert(obj, index);
		return obj;
	}

// 	obj.appendHTML = html => {
// 		obj.innerHTML = obj.innerHTML + html;
// 		return obj;
// 	}

	obj.empty = () => {
		while (obj.firstChild)
			obj.removeChild(obj.firstChild);
		return obj;
	}

	obj.getIndex = () => {
		return obj.parentElement.children.indexOf(obj);
	}

	obj.show = () => {
		obj.style.display = "block";
		return obj;
	}

	obj.hide = () => {
		obj.style.display = "none";
		return obj;
	}

	// Add one event listener on node
	obj.on = (event, callback) => {
		obj.addEventListener(event, callback);
		return obj;
	}
	
	// Add several event listeners on same node
	obj.onAll = (events, callback) => {
		events.split(" ").map( e => {
			if(e !== undefined)
				obj.on(e, callback);
		});
		return obj;
	}
	
	// Get node parent
	obj.parent = () => {
		const parent = obj.parentElement;
		assocFuncs(parent);
		return parent;
	}
	
	obj.clone = recursive => {
		const node = obj.cloneNode(recursive);
		assocFuncs(node);
		return node;
	}
}

export default nq;
